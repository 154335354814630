<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import moment from "moment";
import {
  discountRateList,
  discountRateListSummary,
  discountRateListTrend,
} from "@/api/decision/businessReport/discountRate"; //折扣率分析
export default {
  name: "discountRate",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        listNo: true,
        loading: true,
        isRestTablecolumns: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "折扣率分析",
        rowKey: "shopId",
        search: [
          {
            label: "销售月份",
            type: "month",
            filter: "billDate",
            disabledFuture: true,
            model: moment().format("YYYY-MM"),
            defaultValue: moment().format("YYYY-MM"),
            filterFnc: (a) => ({ billDate: a + "-01" + " 00:00:00" }),
          },
          {
            type: "filters",
            tip: "门店编码/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            type: "textTip",
            tip: "数据统计截止到昨天",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "dateShop",
        tabsColumns: [
          {
            getListApi: discountRateList,
            uuid: "494faa9e-2932-d057-b295-c31c9fc6794c",
            // getSummaryApi: discountRateListSummary,
            title: "折扣率",
            type: "dateShop",
            exportOption: {
              fastExportUrl: "/api/system/shop/strategy/discountRate/listExport",
              exportName: "折扣率",
            },
            // defaultBody: { summaryType: 1 },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "date",
                label: "日期",
                minWidth: 150,
                getColumns: () =>
                  this.options.list?.[0]?.returnRateDates?.map?.((x) => ({
                    ...x,
                    label: x.day + "号",
                    prop: `returnRateDates.${x.day}`,
                    minWidth: 110,
                    // summary: true,
                    formatter: (v, row) => {
                      return (
                        row.returnRateDates?.find((y) => y.day === x.day)?.discountRate +
                          "%" || 0
                      );
                    },
                  })) || [],
              },
            ],
          },
          {
            title: "折扣率图表",
            type: "guest",
            getListApi: discountRateListTrend,
            uuid: "4386ebf5-05a2-6d29-1cd7-cb6eeb248e2b",
            // getSummaryApi: '',
            getDataKey: { list: "data" },
            getEchartsOption: async () => {
              await this.handleEvent("setIntervalList");
              await this.$nextTick();
              return {
                tooltip: {
                  trigger: "axis", // 提示框触发类型
                  axisPointer: {
                    crossStyle: {
                      color: "#73d13d", // 十字准星的颜色
                    },
                  },
                  formatter: (params) => {
                    console.log("params", params);
                    return `日期:${params[0].name}<br>折扣率:${params[0].data}(%)`; // 提示框内容格式化
                  },
                },
                legend: {
                  data: ["折扣率"], // 图例名称
                  itemWidth: 30, // 设置图例项的宽度，增加线的长度
                  itemHeight: 0, // 设置图例项的高度为0，隐藏中间的点
                  textStyle: {
                    fontSize: 14, //文本大小
                    color: "#333", // 图例文本颜色
                  },
                },
                grid: {
                  left: "1%", // 图表左边空白
                  right: "1%", // 图表右边空白
                  bottom: "5%", // 图表底部空白
                  containLabel: true, // 包含坐标轴的刻度标签
                },
                xAxis: [
                  {
                    type: "category", // x 轴类型为类目轴
                    data: this.interval.dateStrArr, // x 轴数据
                    axisTick: {
                      show: false, // 不显示刻度线
                    },
                    axisLine: {
                      lineStyle: {
                        color: "#dddddd", // x 轴线颜色
                      },
                    },
                    boundaryGap: false, // 两端空白策略，false 表示留白
                    axisLabel: {
                      color: "#cccccc", // x 轴文本的颜色
                      fontSize: 14, // x 轴标签字体大小
                    },
                  },
                ],
                yAxis: [
                  {
                    type: "value", // y 轴类型为数值轴
                    name: "折扣率(%)", // y 轴名称
                    nameGap: 25, // 设置标题与y轴的距离
                    axisLine: {
                      show: false, // 不显示坐标轴轴线
                      lineStyle: {
                        color: "#999999", // y 轴线颜色
                      },
                    },
                    axisTick: {
                      show: false, // 不显示刻度线
                    },
                    splitLine: {
                      lineStyle: {
                        type: "dashed", // 分隔线样式为虚线
                        color: "#E8E8E8", // y 轴线颜色
                      },
                    },
                    axisLabel: {
                      // formatter: '{value} 元', // 自定义刻度线显示格式，显示数值和单位
                      fontSize: 14, // y 轴标签字体大小
                    },
                  },
                ],
                series: [
                  {
                    name: "折扣率(%)", // 系列名称
                    type: "line", // 系列类型为折线图
                    data: this.interval.payMoneyArr, // 系列数据
                    yAxisIndex: 0, // 使用的 y 轴索引
                    symbol: "circle", // 数据点图形
                    symbolSize: 8, // 数据点大小
                    itemStyle: {
                      color: "#40B5FF", // 数据项样式
                    },
                    lineStyle: {
                      color: "#40B5FF", // 折线样式
                    },
                  },
                ],
              };
            },
          },
        ],
      },
      interval: {}, //图表
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "setIntervalList":
          this.interval = {
            dateStrArr: [],
            payMoneyArr: [],
          };
          this.options.list.forEach((range, i) => {
            this.interval.dateStrArr.push(range.billDateStr);
            this.interval.payMoneyArr.push(range.discountRate);
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
