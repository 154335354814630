<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  supplierSaleList,
  supplierSaleListSummary,
} from "@/api/decision/saleRankReport/supplier"; //供应商销售排行
export default {
  name: "saleRankReportSupplier",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "供应商销售排行",
        rowKey: "shopId",
        search: [
          {
            label: "销售时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "partnerNos", label: "供应商编码" },
              { filter: "partnerNames", label: "供应商名称" },
            ],
          },
          this.$select({
            key: "listSupplier",
            option: {
              filter: "partnerIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择供应商",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "supplier" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "salesVolume",
        tabsColumns: [
          {
            title: "销量排行",
            type: "salesVolume",
            getSummaryApi: supplierSaleListSummary,
            getListApi: supplierSaleList,
            uuid: "5f4626ef-a741-0bfc-2893-fe40aad27425",
            defaultBody: { rankingType: 1 },
            exportOption: {
              ossKeyUrl:
                "/api/system/shop/strategy/supplySale/ranking/getExportGoodsInfo",
              fastExportUrl: "/api/system/shop/strategy/supplySale/ranking/export",
              exportName: "供应商销量排行",
            },
            columns: [
              {
                prop: "partnerNo",
                label: "供应商编码",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "供应商名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "总优惠金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "lineGain", "agioMoneySum"],
          },
          {
            title: "销售额排行",
            type: "salesMoney",
            getSummaryApi: supplierSaleListSummary,
            getListApi: supplierSaleList,
            uuid: "7a91aa80-422b-ea0a-3faf-7aabf24a408d",
            defaultBody: { rankingType: 2 },
            exportOption: {
              ossKeyUrl:
                "/api/system/shop/strategy/supplySale/ranking/getExportGoodsInfo",
              fastExportUrl: "/api/system/shop/strategy/supplySale/ranking/export",
              exportName: "供应商销售额排行",
            },
            columns: [
              {
                prop: "partnerNo",
                label: "供应商编码",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "供应商名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "总优惠金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "lineGain", "agioMoneySum"],
          },
          {
            title: "销售毛利排行",
            type: "salesGrossProfit",
            uuid: "6b802b55-cb63-11f5-8de9-a9ab757a8b43",
            getSummaryApi: supplierSaleListSummary,
            getListApi: supplierSaleList,
            defaultBody: { rankingType: 3 },
            exportOption: {
              ossKeyUrl:
                "/api/system/shop/strategy/supplySale/ranking/getExportGoodsInfo",
              fastExportUrl: "/api/system/shop/strategy/supplySale/ranking/export",
              exportName: "供应商销售毛利排行",
            },
            columns: [
              {
                prop: "partnerNo",
                label: "供应商编码",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "供应商名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "总优惠金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "lineGain", "agioMoneySum"],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          break;
        default:
          break;
      }
    },
  },
};
</script>
