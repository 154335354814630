<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { shopsSaleList, shopsSaleListSummary } from "@/api/decision/saleRankReport/shop"; //门店销售排行
export default {
  name: "saleRankReportShops",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "门店销售排行",
        rowKey: "shopId",
        search: [
          {
            label: "销售时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "salesVolume",
        tabsColumns: [
          {
            title: "销量排行",
            type: "salesVolume",
            getSummaryApi: shopsSaleListSummary,
            getListApi: shopsSaleList,
            uuid: "7e9d2664-b30d-8f75-c55b-06270501beb5",
            defaultBody: { rankingType: 1 },
            exportOption: {
              ossKeyUrl: "/api/system/shop/strategy/shopSale/ranking/getExportGoodsInfo",
              fastExportUrl: "/api/system/shop/strategy/shopSale/ranking/export",
              exportName: "门店销量排行",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "总优惠金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "lineGain", "agioMoneySum"],
          },
          {
            title: "销售额排行",
            type: "salesMoney",
            getSummaryApi: shopsSaleListSummary,
            getListApi: shopsSaleList,
            uuid: "d7c27e60-b7bc-79ef-2c3b-97a2ebf433c9",
            defaultBody: { rankingType: 2 },
            exportOption: {
              ossKeyUrl: "/api/system/shop/strategy/shopSale/ranking/getExportGoodsInfo",
              fastExportUrl: "/api/system/shop/strategy/shopSale/ranking/export",
              exportName: "门店销售额排行",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "总优惠金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "lineGain", "agioMoneySum"],
          },
          {
            title: "销售毛利排行",
            type: "salesGrossProfit",
            getSummaryApi: shopsSaleListSummary,
            getListApi: shopsSaleList,
            uuid: "28aa07f4-5784-5c3e-e097-faf5dcc81a90",
            defaultBody: { rankingType: 3 },
            exportOption: {
              ossKeyUrl: "/api/system/shop/strategy/shopSale/ranking/getExportGoodsInfo",
              fastExportUrl: "/api/system/shop/strategy/shopSale/ranking/export",
              exportName: "门店销售毛利排行",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "agioMoneySum",
                label: "总优惠金额",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "lineGain", "agioMoneySum"],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          break;
        default:
          break;
      }
    },
  },
};
</script>
