<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import { saleTimeGoodsList } from "@/api/decision/saleQueryReport/saleTimeGoods"; // 销售时段商品分析
export default {
  name: "saleTimeGoods",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        uuid: "1242b521-d929-309c-c218-a1249c618ce7",
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "销售时段商品分析",
        getListApi: saleTimeGoodsList,
        listNo: true, // 序号
        exportOption: {
          show: true,
          fastExportUrl: "/api/system/shop/strategy/saleTimeSlotGoods/ranking/listExport",
          exportName: "销售时段商品分析",
        },
        //搜索
        search: [
          {
            label: "销售日期",
            type: "date",
            model:
              new Date().getFullYear() +
              "-" +
              String(new Date().getMonth() + 1).padStart(2, "0") +
              "-" +
              String(new Date().getDate()).padStart(2, "0"),
            filter: "billDate",
            defaultValue:
              new Date().getFullYear() +
              "-" +
              String(new Date().getMonth() + 1).padStart(2, "0") +
              "-" +
              String(new Date().getDate()).padStart(2, "0"),
            // filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            type: "filters",
            tip: "类别编码/类别名称/商品编码/商品名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "categoryNos", label: "类别编码" },
              { filter: "categoryNames", label: "类别名称" },
              { filter: "goodsNos", label: "商品编码" },
              { filter: "goodsNames", label: "商品名称" },
            ],
          },
          this.$select({
            key: "listCategory",
            option: {
              filter: "categoryIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品类别",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "category" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //表格
        columns: [
          {
            prop: "categoryNo",
            label: "类别编码",
            minWidth: 150,
          },
          {
            prop: "categoryName",
            label: "类别名称",
            minWidth: 150,
          },
          {
            prop: "goodsNo",
            label: "商品编码",
            minWidth: 150,
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 150,
          },
          {
            prop: "barcode",
            label: "条码",
            minWidth: 150,
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 150,
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 150,
          },
          {
            prop: "payTypes",
            align: "center",
            label: "",
            getColumns: (list) => {
              let colums = [];
              if (list?.[0]?.saleTimeSlots?.length) {
                colums = list[0].saleTimeSlots.map((x, i) => ({
                  label: x + "点",
                  prop: x,
                  children: [
                    {
                      label: "同期",
                      prop: "sameQty",
                      minWidth: 100,
                      summary: true,
                    },
                    {
                      label: "上周",
                      prop: "lastWeekQty",
                      minWidth: 135,
                    },
                    {
                      label: "本日",
                      prop: "todayQty",
                      minWidth: 135,
                    },
                  ].map((y) => ({
                    ...y,
                    prop: `saleTimeSlotGoodsDetailsRespList.${x}.timeSlot.${y.prop}`,
                    summary: true,
                    getValue: (row, props) => {
                      const [key, id, idKey, prop] = props.split(".");
                      return (
                        row?.saleTimeSlotGoodsDetailsRespList?.find?.(
                          (x) => String(x[idKey]) == id
                        )?.[prop] || "0"
                      );
                    },
                  })),
                }));
              }
              return colums;
            },
          },
        ],
        summary: ["sameQty", "lastWeekQty", "todayQty"],
      },
    };
  },
  methods: {
    formattedDate() {
      // 创建一个Date对象
      var today = new Date();

      // 获取年、月、日
      var year = today.getFullYear();
      var month = String(today.getMonth() + 1).padStart(2, "0"); // 月份是从0开始计数的，所以要加1
      var day = String(today.getDate()).padStart(2, "0");

      // 格式化为"YYYY-MM-DD"
      var formattedDate = year + "-" + month + "-" + day;

      // 输出结果
      return formattedDate;
    },
  },
};
</script>
