<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  saleGrossProfitTotalList,
  saleGrossProfitTotalListSummary,
} from "@/api/decision/saleQueryReport/saleGrossProfitTotal"; // 销售毛利汇总
export default {
  name: "saleGrossProfitTotal",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "销售毛利汇总",
        rowKey: "shopId",
        search: [
          {
            label: "销售时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "goodsNos", label: "商品编号" },
              { filter: "goodsNames", label: "商品名称" },
              { filter: "categoryNos", label: "类别编号" },
              { filter: "categoryNames", label: "类别名称" },
              { filter: "shopNos", label: "门店编号" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCategory",
            option: {
              filter: "categoryIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品类别",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "category" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listBrand",
            option: {
              filter: "brandIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择品牌",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "brand" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listSupplier",
            option: {
              filter: "partnerIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择供应商",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "supplier" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "goods",
        tabsColumns: [
          {
            title: "按商品汇总",
            type: "goods",
            getSummaryApi: saleGrossProfitTotalListSummary,
            getListApi: saleGrossProfitTotalList,
            uuid: "da359d9e-37c0-c911-dc2b-2299bd256b3a",
            defaultBody: { summaryType: 1 },
            exportOption: {
              fastExportUrl: "/api/system/shop/strategy/saleGain/ranking/listExport",
              exportName: "销售毛利汇总(按商品汇总)",
            },
            columns: [
              {
                prop: "categoryNo",
                label: "类别编码",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "costPrice",
                label: "销售成本",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "gainRate",
                label: "毛利率",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "costPrice", "lineGain", "gainRate"],
          },
          {
            title: "按商品日汇总",
            type: "goodsDay",
            getSummaryApi: saleGrossProfitTotalListSummary,
            getListApi: saleGrossProfitTotalList,
            uuid: "9419edf9-3a12-30c9-1163-b63f2e322b38",
            defaultBody: { summaryType: 2 },
            exportOption: {
              fastExportUrl: "/api/system/shop/strategy/saleGain/ranking/listExport",
              exportName: "销售毛利汇总(按商品日汇总)",
            },
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 150,
              },
              {
                prop: "categoryNo",
                label: "类别编码",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "costPrice",
                label: "销售成本",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "gainRate",
                label: "毛利率",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "costPrice", "lineGain", "gainRate"],
          },
          {
            title: "按类别汇总",
            type: "category",
            getSummaryApi: saleGrossProfitTotalListSummary,
            getListApi: saleGrossProfitTotalList,
            uuid: "4f7108d3-0858-0526-ade8-0ddb5b92bc91",
            defaultBody: { summaryType: 3 },
            exportOption: {
              fastExportUrl: "/api/system/shop/strategy/saleGain/ranking/listExport",
              exportName: "销售毛利汇总(按类别汇总)",
            },
            columns: [
              {
                prop: "categoryNo",
                label: "类别编码",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "costPrice",
                label: "销售成本",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "gainRate",
                label: "毛利率",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "costPrice", "lineGain", "gainRate"],
          },
          {
            title: "按品牌汇总",
            type: "brand",
            getSummaryApi: saleGrossProfitTotalListSummary,
            getListApi: saleGrossProfitTotalList,
            uuid: "0efa79d2-a694-6923-0b86-b89f0093a409",
            defaultBody: { summaryType: 4 },
            exportOption: {
              fastExportUrl: "/api/system/shop/strategy/saleGain/ranking/listExport",
              exportName: "销售毛利汇总(按品牌汇总)",
            },
            columns: [
              {
                prop: "brandNo",
                label: "品牌编码",
                minWidth: 155,
              },
              {
                prop: "brandName",
                label: "品牌名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "costPrice",
                label: "销售成本",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "gainRate",
                label: "毛利率",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "costPrice", "lineGain", "gainRate"],
          },
          {
            title: "按供应商汇总",
            type: "partner",
            getSummaryApi: saleGrossProfitTotalListSummary,
            getListApi: saleGrossProfitTotalList,
            uuid: "60bbfedd-4196-a383-b348-3e80f6b462a2",
            defaultBody: { summaryType: 5 },
            exportOption: {
              fastExportUrl: "/api/system/shop/strategy/saleGain/ranking/listExport",
              exportName: "销售毛利汇总(按供应商汇总)",
            },
            columns: [
              {
                prop: "partnerNo",
                label: "供应商编码",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "供应商名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "costPrice",
                label: "销售成本",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "gainRate",
                label: "毛利率",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "costPrice", "lineGain", "gainRate"],
          },
          {
            title: "按门店汇总",
            type: "shop",
            getSummaryApi: saleGrossProfitTotalListSummary,
            getListApi: saleGrossProfitTotalList,
            uuid: "c5ef959d-f2dc-3782-a1b1-454fe56e38c5",
            defaultBody: { summaryType: 6 },
            exportOption: {
              fastExportUrl: "/api/system/shop/strategy/saleGain/ranking/listExport",
              exportName: "销售毛利汇总(按门店汇总)",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "costPrice",
                label: "销售成本",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "gainRate",
                label: "毛利率",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "costPrice", "lineGain", "gainRate"],
          },
          {
            title: "按门店日汇总",
            type: "shopDay",
            getSummaryApi: saleGrossProfitTotalListSummary,
            getListApi: saleGrossProfitTotalList,
            uuid: "40bae85f-4188-f347-c658-d3467f08e0c1",
            defaultBody: { summaryType: 7 },
            exportOption: {
              fastExportUrl: "/api/system/shop/strategy/saleGain/ranking/listExport",
              exportName: "销售毛利汇总(按门店日汇总)",
            },
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 150,
              },
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "销售数量",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "costPrice",
                label: "销售成本",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "lineGain",
                label: "毛利",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "gainRate",
                label: "毛利率",
                minWidth: 150,
                sortable: true,
              },
            ],
            summary: ["unitQty", "unitMoney", "costPrice", "lineGain", "gainRate"],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          if (this.options.tabColumnType === "note") {
            this.$router.push({
              name: "saleTotalDetail",
              query: {
                billNo: row.billNo,
                type: "Update",
              },
            });
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
